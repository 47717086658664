import { memo, useState } from "react";
import { Parapara } from "./components/Parapara";
import "./App.css";

const App = memo(() => {
  const [images, setImages] = useState<string[]>([]); // imagesを管理
  const [isUploaded, setIsUploaded] = useState<boolean>(false); // 画像がアップロードされたかどうか

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      // 数値と文字列を正しくソートするロジック
      const sortedFiles = Array.from(files).sort((a, b) => {
        const nameA = a.name;
        const nameB = b.name;

        // 数値かどうかの判定
        const isNumericA = !isNaN(Number(nameA));
        const isNumericB = !isNaN(Number(nameB));

        if (isNumericA && isNumericB) {
          // 両方が数値なら数値として比較
          return Number(nameA) - Number(nameB);
        } else {
          // 文字列として比較
          return nameA.localeCompare(nameB, undefined, { numeric: true });
        }
      });

      // オブジェクトURLを生成
      const uploadedImages = sortedFiles.map((file) =>
        URL.createObjectURL(file)
      );

      // コンソールにファイル名順でURLを表示
      console.log("Uploaded Images:", uploadedImages);

      setImages(uploadedImages); // ソートされた画像をセット
      setIsUploaded(true); // 画像がアップロードされたことを記録
    }
  };

  return (
    <>
      {!isUploaded ? (
        // 画像がアップロードされていない場合、ファイル選択を表示
        <>
          <p>撮影したい原画の画像ファイルを全て選択してください！</p>
          <input
            type="file"
            accept="image/*"
            multiple
            onChange={handleFileUpload}
            id="file-upload"
          />
        </>
      ) : (
        // 画像がアップロードされた後に、Paraparaを表示
        <>
          <Parapara images={images} />
        </>
      )}
    </>
  );
});

export default App;
